import { Album } from "../albums"

const mobiusShip: Album = {
  id: "mobius-ship",
  name: "Möbius Ship",
  people: [
    "adam",
    "adrian",
    "barton",
    "ben",
    "dank",
    "dave",
    "dick",
    "eddie",
    "jack",
    "johnny",
    "mark",
    "stevem",
    "thom",
    "trevor",
  ],
  art: ["dave"],
  mastering: "dave",
  tracks: [],
  releaseDate: "September 15th, 2020",
  recordedDate: "July 18th - August 3rd, 2020",
  coverImage: "mobius-ship.png",
  links: [
    {
      url: "https://thewigout.bandcamp.com/album/m-bius-ship", //LOL umlauts
      description: "Bandcamp",
    },
    {
      url:
        "https://open.spotify.com/album/6JrMHiCfVHWdDFEOPKg3ln?si=NnLEbAJ3TmKuqcYdHmGwtQ",
      description: "Spotify",
    },
    {
      url: "https://tidal.com/browse/album/156022040",
      description: "Tidal",
    },
    {
      url: "https://soundcloud.com/the-wig-out/mobius-ship",
      description: "Soundcloud",
    },
  ],
}

export default mobiusShip
