import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const AlbumCoverImage = styled(Img)<{ borderColor?: string; small?: boolean }>`
  width: 200px;
  max-width: 90vw;
  @media (min-width: 768px) {
    width: ${props => (props.small ? 200 : 600)}px;
    max-width: ${props => (props.small ? 25 : 90)};
  }
  margin: 40px auto;
  ${props =>
    props.borderColor ? `border: 2px solid ${props.borderColor};` : ``}
`

export default ({
  filename,
  borderColor,
  small,
}: {
  filename: string
  borderColor?: string
  small?: boolean
}) => {
  const data = useStaticQuery(graphql`
    query {
      allFile {
        nodes {
          relativePath
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const match = data.allFile.nodes.filter(
    (n: any) => n.relativePath === filename
  )[0]
  if (!match) return null

  return (
    <AlbumCoverImage
      fluid={match.childImageSharp.fluid}
      borderColor={borderColor}
      small={small}
    />
  )
}
