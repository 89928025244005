import { Album } from "../albums"

const keepYourJoyASecret: Album = {
  id: "kyjas",
  name: "Gets Even",
  people: ["adam", "adrian", "dave", "eric", "thom"],
  art: ["eric"],
  mastering: "dave",
  tracks: [],
  releaseDate: "February 23rd, 2021",
  recordedDate: "November 2020 - January 2021",
  coverImage: "kyjas.png",
  links: [
    {
      url:
        "https://open.spotify.com/album/0fgJ0R6LIHwd3jFMiW8vo2?si=DXgC4vj0RWS2v_7OTKWUMw",
      description: "Spotify",
    },
    {
      url: "https://tidal.com/browse/album/174049335",
      description: "Tidal",
    },
    {
      url: "https://music.apple.com/us/album/keep-your-joy-a-secret/1554208951",
      description: "Apple Music",
    },
  ],
}

export default keepYourJoyASecret
