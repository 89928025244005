import styled from "styled-components"

const AlbumText = styled.section`
  max-width: 600px;
  margin: 15px auto;
  @media (min-width: 600px) {
    margin: 10px auto;
    width: 600px;
  }
`
export default AlbumText
