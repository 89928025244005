import { Album, AlbumId } from "./types"
import mobius from "./mobius-ship"
import getsEven from "./gets-even"
import keepYourJoyASecret from "./keep-your-joy-a-secret"
export { Album, AlbumId }

const albums: Album[] = [
  {
    id: "baked-dwarves",
    name: "Baked Dwarves Moan At Harvestman",
    people: ["adam", "barton", "dave", "mark", "shawn", "steve"],
    art: ["adam"],
    mastering: "barton",
    releaseDate: "May 26th, 2020",
    recordedDate: "March and April 2020",
    coverImage: "pink-logo.png",
    links: [
      {
        url:
          "https://open.spotify.com/album/2E4FwMLLbvJYzOgffIKdcZ?si=PC6J-VoDTpCbelTcg67eDQ",
        description: "Spotify",
      },
      { url: "https://tidal.com/album/142356884", description: "Tidal" },
      {
        url: "https://soundcloud.com/the-wig-out/baked-dwarves",
        description: "Soundcloud",
      },
      {
        url:
          "https://music.apple.com/us/album/baked-dwarves-moan-at-harvestman-ep/1514559030",
        description: "Apple Music",
      },
    ],
    tracks: [
      {
        name: "Curvature",
        length: 232,
        files: {
          mp3: "The Wig Out - Curvature.mp3",
          wav: "The Wig Out - Curvature.wav",
        },
      },
      {
        name: "What's up with those Cop Shoes?",
        length: 236,
        files: {
          mp3: "The Wig Out - Whats Up With Those Cop Shoes.mp3",
          wav: "The Wig Out - Whats Up With Those Cop Shoes.wav",
        },
      },
      {
        name: "Knodell Street, Detroit, 3:27AM",
        length: 188,
        files: {
          mp3: "The Wig Out - Knodell Street Detroit 327AM.mp3",
          wav: "The Wig Out - Knodell Street Detroit 327AM.wav",
        },
      },
      {
        name: "Running on the Yellow Line",
        length: 174,
        files: {
          mp3: "The Wig Out - Running On The Yellow Line (Clean).mp3",
          wav: "The Wig Out - Running On The Yellow Line (Clean).wav",
        },
      },
      {
        name: "Index Kinetic",
        length: 183,
        files: {
          mp3: "The Wig Out - Index Kinetic.mp3",
          wav: "The Wig Out - Index Kinetic.wav",
        },
      },
      {
        name: "Undark",
        length: 374,
        files: {
          mp3: "The Wig Out - Undark.mp3",
          wav: "The Wig Out - Undark.wav",
        },
      },
    ],
  },
  {
    id: "number-two",
    name: "Number Two",
    people: ["adrian", "andrew", "dan", "dave", "josh", "matt", "tq"],
    art: ["elise"],
    mastering: "adrian",
    releaseDate: "June 16th, 2020",
    recordedDate: "April and May 2020",
    coverImage: "number-two.png",
    links: [
      {
        url:
          "https://open.spotify.com/album/5tntdAQBdwJj3k0ZCKwMAm?si=3BXsYTEtTAKDfIkTF1Uslg",
        description: "Spotify",
      },
      { url: "https://tidal.com/browse/album/144690234", description: "Tidal" },
      {
        url: "https://thewigout.bandcamp.com/album/number-two",
        description: "Bandcamp",
      },
      {
        url: "https://music.apple.com/us/album/number-2/1517928875?app=music",
        description: "Apple Music",
      },
    ],
    tracks: [
      {
        name: "Sounds Like Fun",
        length: 308,
        files: {
          mp3: "2/The Wig Out - Sounds Like Fun.mp3",
          wav: "2/The Wig Out - Sounds Like Fun.wav",
        },
      },
      {
        name: "Stoned Fisher",
        length: 370,
        files: {
          mp3: "2/The Wig Out - Stoned Fisher.mp3",
          wav: "2/The Wig Out - Stoned Fisher.wav",
        },
      },
      {
        name: "Electrolytes!",
        length: 330,
        files: {
          mp3: "2/The Wig Out - Electrolytes.mp3",
          wav: "2/The Wig Out - Electrolytes.wav",
        },
      },
      {
        name: "Glow Acres",
        length: 264,
        files: {
          mp3: "2/The Wig Out - Glow Acres.mp3",
          wav: "2/The Wig Out - Glow Acres.wav",
        },
      },
      {
        name: "Aloha Also Means Goodbye",
        length: 181,
        files: {
          mp3: "2/The Wig Out - Aloha Also Means Goodbye.mp3",
          wav: "2/The Wig Out - Aloha Also Means Goodbye.wav",
        },
      },
      {
        name: "Tyrannosaurus Yes",
        length: 230,
        files: {
          mp3: "2/The Wig Out - Tyrannosaurus Yes.mp3",
          wav: "2/The Wig Out - Tyrannosaurus Yes.wav",
        },
      },
      {
        name: "Stay At Homeostasis",
        length: 351,
        files: {
          mp3: "2/The Wig Out - Stay At Homeostasis.mp3",
          wav: "2/The Wig Out - Stay At Homeostasis.wav",
        },
      },
    ],
  },
  {
    id: "we-have-a-problem",
    name: "We Have A Problem",
    people: ["adam", "alex", "barton", "dave", "mark", "steve"],
    art: ["genny"],
    mastering: "barton",
    releaseDate: "July 7th, 2020",
    recordedDate: "May and June 2020",
    coverImage: "problem.png",
    links: [
      {
        url:
          "https://open.spotify.com/album/56ILdryVJVhFdBW6lSkQvU?si=kxVTNqDCTvmTjeuGzdHPeg",
        description: "Spotify",
      },
      { url: "https://tidal.com/browse/album/147234346", description: "Tidal" },
      {
        url: "https://thewigout.bandcamp.com/album/we-have-a-problem",
        description: "Bandcamp",
      },
    ],
    tracks: [
      {
        name: "Blap Em, Danno!",
        length: 240,
        files: {
          mp3: "3/The Wig Out - Blap Em Danno.mp3",
          wav: "3/The Wig Out - Blap Em Danno.wav",
        },
      },
      {
        name: "Circus At Gunpoint",
        length: 211,
        files: {
          mp3: "3/The Wig Out - Circus At Gunpoint.mp3",
          wav: "3/The Wig Out - Circus At Gunpoint.wav",
        },
      },
      {
        name: "6480 St. Laurent, Montreal, 10:37AM",
        length: 237,
        files: {
          mp3: "3/The Wig Out - 6480 St Laurent.mp3",
          wav: "3/The Wig Out - 6480 St Laurent.wav",
        },
      },
      {
        name: "Soft Apocalypse",
        length: 327,
        files: {
          mp3: "3/The Wig Out - Soft Apocalypse.mp3",
          wav: "3/The Wig Out - Soft Apocalypse.wav",
        },
      },
      {
        name: "Somebody's Odd Extruded Personal Vision",
        length: 250,
        files: {
          mp3: "3/The Wig Out - Somebodys Odd Extruded Personal Vision.mp3",
          wav: "3/The Wig Out - Somebodys Odd Extruded Personal Vision.wav",
        },
      },
      {
        name: "Laser Envy",
        length: 453,
        files: {
          wav: "3/The Wig Out - Laser Envy.mp3",
          mp3: "3/The Wig Out - Laser Envy.wav",
        },
      },
    ],
  },
  {
    id: "essential-trips",
    name: "Essential Trips",
    people: ["adrian", "dan", "dave", "matt", "thom", "tq"],
    art: ["dave"],
    mastering: "adrian",
    releaseDate: "July 28th, 2020",
    recordedDate: "June and July 2020",
    coverImage: "essential-trips.png",
    links: [
      {
        url:
          "https://open.spotify.com/album/0b2zWynE1HQBh5joxjeu4e?si=hqnNW_aKQDmtkViAaSUCwA",
        description: "Spotify",
      },
      { url: "https://tidal.com/browse/album/149692949", description: "Tidal" },
      {
        url: "https://thewigout.bandcamp.com/album/essential-trips",
        description: "Bandcamp",
      },
    ],
    tracks: [
      {
        name: "Sauce Policy (Explicit)",
        length: 396,
        files: {
          mp3: "4/The Wig Out - Sauce Policy - Explicit.mp3",
          wav: "4/The Wig Out - Sauce Policy - Explicit.wav",
        },
      },
      {
        name: "Charm Bracelet Offensive",
        length: 321,
        files: {
          mp3: "4/The Wig Out - Charm Bracelet Offensive.mp3",
          wav: "4/The Wig Out - Charm Bracelet Offensive.wav",
        },
      },
      {
        name: "Broken Ligature",
        length: 158,
        files: {
          mp3: "4/The Wig Out - Broken Ligature.mp3",
          wav: "4/The Wig Out - Broken Ligature.wav",
        },
      },
      {
        name: "A Fortune Lost",
        length: 231,
        files: {
          mp3: "4/The Wig Out - A Fortune Lost.mp3",
          wav: "4/The Wig Out - A Fortune Lost.wav",
        },
      },
      {
        name: "Permission For Remission",
        length: 323,
        files: {
          mp3: "4/The Wig Out - Permission For Remission.mp3",
          wav: "4/The Wig Out - Permission For Remission.wav",
        },
      },
      {
        name: "Sauce Meditation",
        length: 100,
        files: {
          wav: "4/The Wig Out - Sauce Meditation.mp3",
          mp3: "4/The Wig Out - Sauce Meditation.wav",
        },
      },
      {
        name: "Sauce Policy (Radio Edit)",
        length: 396,
        files: {
          mp3: "4/The Wig Out - Sauce Policy - Clean.mp3",
          wav: "4/The Wig Out - Sauce Policy - Clean.wav",
        },
      },
    ],
  },
  {
    id: "quintessence",
    name: "Quintessence",
    people: ["adam", "barton", "dave", "mark", "steve", "tq"],
    art: ["dave", "adam"],
    mastering: "dave",
    releaseDate: "August 18th, 2020",
    recordedDate: "June and July 2020",
    coverImage: "quintessence.png",
    links: [
      {
        url:
          "https://open.spotify.com/album/17u2yujlHa6P3n0xo6pG0g?si=zFabg5eiRZ2m69m7uM28EA",
        description: "Spotify",
      },
      {
        url: "https://thewigout.bandcamp.com/album/quintessence",
        description: "Bandcamp",
      },
    ],
    tracks: [
      {
        name: "Chicago Ave. and East 38th Street, Minneapolis, 8:57PM",
        length: 314,
        files: {
          mp3: "5/The Wig Out - Chicago Ave and East 38th Street.mp3",
          wav: "5/The Wig Out - Chicago Ave and East 38th Street.wav",
        },
      },
      {
        name: "Perfect Hair Forever",
        length: 223,
        files: {
          mp3: "5/The Wig Out - Perfect Hair Forever.mp3",
          wav: "5/The Wig Out - Perfect Hair Forever.wav",
        },
      },
      {
        name: "Rollin' The Clover, Bombin' The Alley",
        length: 211,
        files: {
          mp3: "5/The Wig Out - Rollin The Clover Bombin The Alley.mp3",
          wav: "5/The Wig Out - Rollin The Clover Bombin The Alley.wav",
        },
      },
      {
        name: "Motorboater",
        length: 261,
        files: {
          mp3: "5/The Wig Out - Motorboater.mp3",
          wav: "5/The Wig Out - Motorboater.wav",
        },
      },
      {
        name: "Forks And Kites",
        length: 128,
        files: {
          mp3: "5/The Wig Out - Forks And Kites.mp3",
          wav: "5/The Wig Out - Forks And Kites.wav",
        },
      },
      {
        name: "Astrodissonauts",
        length: 360,
        files: {
          mp3: "5/The Wig Out - Astrodissonauts.mp3",
          wav: "5/The Wig Out - Astrodissonauts.wav",
        },
      },
      {
        name: "Never Meant To Be This Far Down Alone",
        length: 219,
        files: {
          mp3: "5/The Wig Out - Never Meant To Be This Far Down Alone.mp3",
          wav: "5/The Wig Out - Never Meant To Be This Far Down Alone.wav",
        },
      },
    ],
  },
  mobius,
  getsEven,
  keepYourJoyASecret,
]

export const getAlbum = (id: AlbumId): Album => albums.find(a => a.id === id)
export const getAlbums = () => albums.slice()
