import { Album } from "../albums"

const mobiusShip: Album = {
  id: "gets-even",
  name: "Gets Even",
  people: ["adam", "adrian", "barton", "dave", "tq", "mark", "thom"],
  art: ["genny"],
  mastering: "adrian",
  tracks: [],
  releaseDate: "December 4th, 2020",
  recordedDate: "October - November, 2020",
  coverImage: "gets-even.png",
  links: [
    {
      url: "https://thewigout.bandcamp.com/album/gets-even",
      description: "Bandcamp",
    },
    {
      url:
        "https://open.spotify.com/album/4CO9acMQrKmoxiyJLsdyWM?si=EypuHBSxSo-5DoSLGSo_xg",
      description: "Spotify",
    },
    {
      url: "https://tidal.com/album/164301459",
      description: "Tidal",
    },
  ],
}

export default mobiusShip
